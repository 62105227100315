import SupportPage from "./pages/SupportPage"
import News from "./pages/News"
import PageHeader from "./pages/PageHeader";
import PageFooter from "./pages/PageFooter";
import MaintenancePage from "./pages/MaintenancePage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import i18n from './i18';

export const baseUrl = i18n.resolvedLanguage ?? "en";

const App = () => {
  return (
    <BrowserRouter>
      <div className="h-screen flex flex-col">
        <div className="h-25 bg-gray-50">
          <div className="w-full max-w-4xl mx-auto m-5">
            <PageHeader />
          </div>
        </div>
        <div className="h-90 flex flex-col items-center justify-center bg-white">
          <div className="w-full max-w-4xl mx-auto">
            <Routes>
              <Route index element={<SupportPage />} />
              <Route path={'/:lng'} element={<SupportPage />} />
              <Route path={`/:lng/news`} element={<News />} />
              <Route path={`/:lng/maintanance`} element={<MaintenancePage />} />
              <Route path={`/maintanance`} element={<MaintenancePage />} />
            </Routes>
          </div>
          <div className="w-full max-w-4xl mx-auto">
            <PageFooter />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
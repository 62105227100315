import Breadcrumb from "../components/Breadcrumb";
import { useTranslation } from 'react-i18next';

const News = () => {
  const { t } = useTranslation();

  const newsEntries = [
    {
      date: "2023-10-12",
      titie: "Cloud DX Pulsewave PAD1 Retirement Notice",
      content: (
        <div>
          <p className="text-sm">The Pulsewave PAD1A wrist blood pressure monitor, which is a Cloud DX designed and manufactured device, is coming to its end of life and is being retired.</p>
          <p className="text-sm">What does this mean: Beginning November 2023, Cloud DX will no longer support or replace any PAD1As. The functionality of the PAD1A devices is not affected. Patients may continue to take their blood pressure readings using PAD1A and the clinicians may continue to view the readings and set/manage notifications on the Connected Health website.</p>
          <br />
          <p className="text-sm"><em>Next Steps:</em></p>
          <p className="text-sm">Please direct any inquiries to the <a href="mailto:DST@clouddx.com" className="text-blue-500">Deployment and Customer Success team</a>. DST team will work with you to integrate other blood pressure devices into your Connected Health kits to continue supporting patients with their health journey.</p>
        </div>
      ),
      id: "20231012-pad1a-retirement-notice"
    },
    {
      date: "2022-05-16",
      titie: "Cloud DX Pulsewave PAD1 Retirement Notice",
      content: (
        <div>
          <p className="font-bold">Cloud DX Pulsewave Acquisition System Retirement Notice</p>
          <p className="text-sm">Cloud DX Pulse Acquisition Software (PAS) version 2.2.0 for Windows and MacOS will be retired on 28 June 2022. From this date forward, this product will no longer be available for download, will not be supported by Cloud DX customer support, and no further bug fixes, updates, or patches will be provided. This is part of the Cloud DX long-term strategy to build the industry-leading Remote Patient Monitoring solution for mobile and web.</p>
          <p className="text-sm">Cloud DX recommends that users download and install the Cloud DX mobile application for their mobile device (Android or iOS) or access the Connected Health Platform on the web at <a href="https://ca.pulsewavedx.com" className="text-blue-500">https://ca.pulsewavedx.com</a>.</p>
          <p className="text-sm">The mobile software may be downloaded from the Google Play Store (for Android) and the Apple Store (for iOS).</p>
          <p className="text-sm">For assistance, please contact Cloud DX customer support.</p>
          <p className="text-sm">Email: <a href="mailto:support@clouddx.com" className="text-blue-500">support@clouddx.com</a></p>
          <p className="text-sm">web: <a href="http://support.pulsewavedx.com" className="text-blue-500">http://support.pulsewavedx.com</a></p>
          <p className="text-sm">toll-free phone: <a href="tel:1-888-543-0944" className="text-blue-500">1-888-543-0944</a></p>
        </div>
      ),
      id: "20220516-pas-retirement-notice"
    }
  ]

  const pageInfo = {
    route: "/news", 
    title: "News"
  }

  return (
    <div className="container overflow-hidden">
      <div>
        <Breadcrumb current={pageInfo}/>
      </div>
      <div className="mt-10">
        <h1 className="my-6 font-display text-4xl font-extrabold text-grey-900">{t('supportNews')}</h1>
      </div>
      {newsEntries.map((entry, index) => (
        <div key={index} className="mt-10" id={entry.id}>
          <p className="font-bold">{entry.date}</p>
          <p className="font-bold">{entry.title}</p>
          <p className="text-sm">
            {entry.content}
          </p>
        </div>
      ))}
    </div>
  ); 
}

export default News;
import logo from'../../assets/images/clouddx_logo.png';

const PageHeader = () => {
  return (
    <div className="mx-auto">
      <a href="https://www.clouddx.com" title="Cloud DX" target="_blank">
        <img src={logo} alt="Cloud DX logo" className="block h-auto max-w-full scale-90"/>
      </a>
    </div>
  );
}

export default PageHeader;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import frTranslation from './locales/fr.json';

import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  order: ['path', 'querystring'],
  lookupQuerystring: "lng",
  caches: ['lng'],
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      fr: {
        translation: frTranslation
      }
    },
    fallbackLng: 'en',
    language: 'en',
    detection: options,
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;

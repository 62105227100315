import ZohoBtn from "../components/ZohoBtn";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Resources = () => {
  const { t } = useTranslation();
  let { lng } = useParams();

  return (
    <div className="mt-6">
      <div className="space-y-1">
        <h3 className="font-display text-lg leading-6 text-gray-900">Resources</h3>
        <p className="max-w-2xl text-sm text-gray-500">{t('cloudDXProducts')}</p>
      </div>
      <div className="mt-6 mx-auto max-w-7xl">
        <div className="flex">
          <a href="https://play.google.com/store/apps/details?id=com.clouddx.pulsewave.dx" target="_blank" className="inline-block bg-blue-500 hover:bg-gray-700 text-white font-bold py-3 px-4 mr-2 rounded-md w-15 uppercase text-center">
            {t('androidApp')}
          </a>
          <a href="https://configure.samsungknox.com" target="_blank" className="inline-block bg-blue-500 hover:bg-gray-700 text-white font-bold py-3 px-4 mr-2 rounded-md w-15 uppercase text-center">
            {t('knoxEnrollment')}
          </a>
          <a href={(lng ?? 'en') + "/news"} className="inline-block bg-blue-500 hover:bg-gray-700 text-white font-bold py-3 mr-2 px-4 rounded-md w-15 uppercase text-center">
            {t('news')}
          </a>
          <ZohoBtn/>
        </div>
      </div>
    </div>
  );
}

export default Resources;
import { useTranslation } from 'react-i18next';

const HoursOfOperation = () => {
  const { t } = useTranslation();

  return (    
    <div className="mt-6">
      <h1 className="my-6 font-display text-4xl font-extrabold text-grey-900">{t('contactingSupport')}</h1>
      <div className="space-y-1">
        <h3 className="font-display text-lg leading-6 text-gray-900">{t('hoursOfOperation')}</h3>
        <p className="max-w-2xl text-sm text-gray-500">{t('supportHours')}</p>
      </div>
      <div className="mt-6 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-medium font-medium text-gray-500">{t('mondayFriday')}</dt>
            <dd className="mt-1 flex text-medium text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="mr-4">{t('monFriHours')}</span>
              <span className="ml-4">{t('easternTime')}</span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-medium font-medium text-gray-500">{t('weekendHolidays')}</dt>
            <dd className="mt-1 flex text-medium text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="mr-4">{t('weekendHours')}</span>
              <span className="ml-4">{t('easternTime')}</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default HoursOfOperation;
{
    "welcomeMessage": "Bonjour et merci d'avoir choisi Cloud DX ® Connected Health !",
    "ourPerks": "Nuestras ventajas",
    "phone": "Téléphone",
    "email": "Correo Electrónico",
    "startSupportRequestWithUs": "Démarrez une demande d'assistance avec nous",
    "contactingSupport": "Contacter l'assistance",
    "hoursOfOperation": "Heures d'ouverture",
    "supportHours": "Voici les heures pendant lesquelles vous pouvez contacter notre équipe d'assistance pour vous aider.",
    "mondayFriday": "Lundi - Vendredi",
    "weekendHolidays": "Week-end - Jours fériés",
    "monFriHours": "8:30 - 20:00",
    "weekendHours": "9:00 - 17:00",
    "easternTime": "heure de l'Est",
    "scheduledMaintenance": "Maintenance planifiée",
    "maintenanceMessage": "La plateforme Connected Health est en cours de maintenance programmée et sera inaccessible à tous les utilisateurs pendant cette période. Toutes les opérations reprendront immédiatement après la fin de cette maintenance.",
    "contactAssistance": "Pour toute assistance, veuillez contacter",
    "customerSupport": "Cloud DX service client",
    "resources": "Ressources",
    "cloudDXProducts": "Une liste de guides, d'articles de la base de connaissances et de liens pour tous vos produits Cloud DX",
    "connectedHealthApp": "Application Santé Connectée",
    "news": "Nouvelles",
    "androidApp": "Android App",
    "knoxEnrollment": "Inscription de Knox",
    "createSupportTicket": "Créer un ticket d'assistance"
}

import { useTranslation } from 'react-i18next';

const WelcomeMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="content-center mt-12 mb-4">
      <h3 className="font-display text-xl">{t('welcomeMessage')}</h3>
    </div>
  );
}

export default WelcomeMessage;
import { useTranslation } from 'react-i18next';

const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h1 className="my-6 font-display text-4xl font-extrabold text-grey-900">
        {t("scheduledMaintenance")}
      </h1>
      <h2 className="my-4 font-display text-2xl font-bold text-grey-700">
        Sep 7, 2023 7PM ET / 5PM MT - Sep 8, 2023 6AM ET / 4AM MT
      </h2>
      <p className="my-2">
        {t("maintenanceMessage")}
      </p>
      <p className="my-2">
        {t("contactAssistance")} <a className="text-sky-400 hover:text-grey-300" href="https://support.pulsewavedx.com" alt="Cloud DX Support">Cloud DX customer support</a>.
      </p>
    </div>
  );
};

export default MaintenancePage;

import ContactUs from "./ContactUs";
import HoursOfOperation from "./HoursOfOperation";
import WelcomeMessage from "./WelcomeMessage";
import Resources from "./Resources";

const SupportPage = () => {
  return (
    <div className="container">      
      <WelcomeMessage />
      <HoursOfOperation />
      <ContactUs />
      <Resources />
    </div>
  );
}

export default SupportPage;
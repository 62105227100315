import { PhoneIcon, MailIcon, ChatIcon } from "@heroicons/react/solid"
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
  const { t } = useTranslation();
  const onBtnClick = () => {
    document.getElementById('zohohc-asap-web-launcherbox')?.click();
  }

  const perks = [
    { name: '1.888.543.0944', description: t("phone"), icon: PhoneIcon, href: "tel:1-888-543-0944"  },
    { name: 'support@clouddx.com', description: t("email"), icon: MailIcon, href: "mailto:support@clouddx.com"},
    { name: 'Support Ticket', description: t("startSupportRequestWithUs"), icon: ChatIcon, onClick: onBtnClick },
  ]

  return (
    <div className="bg-white">
      <h2 className="sr-only">{t('ourPerks')}</h2>
      <div className="mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x lg:py-8">
        {perks.map((perk, perkIdx) => (              
            <div key={perkIdx} className="py-8 lg:w-1/3 lg:flex-none lg:py-0 group">
              <div className="mx-auto flex max-w-xs items-center px-4 lg:max-w-none lg:px-8 group-hover:rounded-lg:ring-1:bg-sky-500">
                <perk.icon className="h-8 w-8 flex-shrink-0 text-sky-500" aria-hidden="true" />
                <div className="ml-4 flex flex-auto flex-col-reverse">
                  {
                    perk.href == "#zohohc" ? (
                      <button onClick={window['startZoho']} className="group-hover:text-sky-500">
                        {perk.name}
                      </button>
                    ) : (
                      <a href={perk.href} onClick={perk.onClick}>
                        <h3 className="font-medium text-gray-900 group-hover:text-sky-500">{perk.name}</h3>
                      </a>
                    )
                  }
                  <p className="text-sm text-gray-500">{perk.description}</p>
                </div>
              </div>
            </div>
        ))}
      </div>
    </div>
  )
}
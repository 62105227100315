import React from 'react';
import { useParams } from 'react-router-dom';

const Breadcrumb = ({ current }) => {
  let { lng } = useParams();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <a href={`/${lng}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
            Home
          </a>
        </li>
        <li>
          <div className="flex items-center">
            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
            </svg>
            <a href={current.route} className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{current.title}</a>
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
{
    "welcomeMessage": "Hola y gracias por elegir Cloud DX Connected Health!",
    "ourPerks": "Nuestras ventajas",
    "phone": "Teléfono",
    "email": "Correo Electrónico",
    "startSupportRequestWithUs": "Inicie una solicitud de soporte con nosotros",
    "contactingSupport": "Contactar atención al cliente",
    "hoursOfOperation": "Horas de operación",
    "supportHours": "Estos son los horarios donde podrá comunicarse con el equipo de soporte y ayudarlo.",
    "mondayFriday": "Lunes - Viernes",
    "weekendHolidays": "Fin de semana - Vacaciones",
    "monFriHours": "8:30 - 20:00",
    "weekendHours": "9:00 - 17:00",
    "easternTime": "hora del este",
    "scheduledMaintenance": "Mantenimiento Programado",
    "maintenanceMessage": "La plataforma de Connected Health está en mantenimiento y no podrá ser accesible durante este tiempo. Todas las operaciones se reanudarán inmediatamente después de que se complete este mantenimiento.",
    "contactAssistance": "Para cualquier ayuda, por favor contacte",
    "customerSupport": "Cloud Dx atención al cliente",
    "resources": "Recursos",
    "cloudDXProducts": "Listas de guías, artículos y enlaces para todos los productos de Cloud DX",
    "connectedHealthApp": "Aplicación de Connected Health",
    "news": "Noticias",
    "androidApp": "Android App",
    "knoxEnrollment": "inscripción de Knox",
    "createSupportTicket": "Crear un tiquete"
}

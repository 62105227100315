{
    "welcomeMessage": "Hello and thank you for choosing Cloud DX Connected Health!",
    "ourPerks": "Our Perks",
    "phone": "Phone",
    "email": "Email",
    "startSupportRequestWithUs": "Start a support request with us",
    "contactingSupport": "Contacting Support",
    "hoursOfOperation": "Hours of Operation",
    "supportHours": "Here are the hours where you can reach out to our support team to assist you.",
    "mondayFriday": "Monday - Friday",
    "weekendHolidays": "Weekend - Holidays",
    "monFriHours": "8:30 am - 8:00 pm",
    "weekendHours": "9:00 am - 5:00 pm",
    "easternTime": "Eastern Time",
    "scheduledMaintenance": "Scheduled Maintenance",
    "maintenanceMessage": "Connected Health platform is undergoing our scheduled maintenance and will be inaccessible to all users during this time. All operations will resume immediately after this maintenance is completed.",
    "contactAssistance": "For any assistance, please contact",
    "customerSupport": "Cloud DX customer support",
    "resources": "Resources",
    "cloudDXProducts": "A list of guides, knowledge base articles and links for all your Cloud DX products",
    "connectedHealthApp": "Connected Health App",
    "news": "News",
    "androidApp": "Android App",
    "knoxEnrollment": "Knox Enrollment",
    "createSupportTicket": "Create Support Ticket",
    "supportNews": "Support News"
}
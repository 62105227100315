import { useTranslation } from 'react-i18next';

export default function ZohoBtn() {
    const { t } = useTranslation();

    const onBtnClick = () => {
        document.getElementById('zohohc-asap-web-launcherbox')?.click();
    }

    return (
        <button className="inline-block bg-blue-500 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded-md w-15 uppercase text-center"
            onClick={onBtnClick}>
            {t('createSupportTicket')}
        </button>
    )
}